import {
  AgeGroup,
  SubscriptionProductFieldSales,
  SubscriptionProductFragment,
  SubscriptionProductIap,
  SubscriptionProductPartnerSales,
  SubscriptionProductStandard,
  SubscriptionProductUniqueSellingPoint,
} from 'gql/generated';
import { Account } from '@telia-company/tv.common-sdk-authentication/dist/Account';
import * as commonGraph from './backend/commonGraph';
import * as cvsGateway from './backend/cvsGateway';
import * as engagementGateway from './backend/engagementGateway';
import * as engagementInformationGateway from './backend/engagementInformationGateway';
import { UserNotFoundError, UserUpdateChildLockError } from 'errorTypes';
import { trackSetUserProperties } from 'tracking';
import { CacheManager } from '@telia-company/tv.common-sdk/dist/CacheManager';
export { AgeGroup } from 'gql/generated';

const cacheClass = 'userService';

type Avatar = {
  url: string;
};

type Profile = {
  id: string;
  ageGroup: AgeGroup;
  alias: string;
  avatar: Avatar;
};

export type UserSubscriptionType =
  | 'standard'
  | 'tve'
  | 'iap'
  | 'partner_sales'
  | 'field_sales'
  | 'fallback'
  | 'dual_entry'
  | 'staff';

export type UserSubscription =
  | UserSubscriptionDualEntry
  | UserSubscriptionFallback
  | UserSubscriptionFieldSales
  | UserSubscriptionIAP
  | UserSubscriptionPartnerSales
  | UserSubscriptionStaff
  | UserSubscriptionStandard
  | UserSubscriptionTve
  | UserSubscriptionExternal;

export type PackageType = 'VAS' | 'STANDARD';

export type UserSubscriptionService = {
  id: string;
  iconUrl?: string;
  name: string;
  packageType: PackageType;
  activationUrl?: string;
};

type UserSubscriptionBase = {
  type: UserSubscriptionType;
  id: string;
  name: string;
  external: boolean;

  services: UserSubscriptionService[];

  // A subscription can also be a VAS and should be treated as it can be activated as well
  packageType: PackageType;
  activationUrl?: string;
};

type UserSubscriptionExternal = UserSubscriptionBase & {
  type: UserSubscriptionType;
  id: string;
  name: string;
  external: true;
};

type UniqueSellingPoint = {
  sellingPoint: string;
  description?: string;
};

type Price = {
  readable: string;
};

type UserSubscriptionStandard = UserSubscriptionBase & {
  type: 'standard';
  price: Price;
  label?: string;
  uniqueSellingPoints: UniqueSellingPoint[];
};

type UserSubscriptionDualEntry = UserSubscriptionBase & {
  type: 'dual_entry';
};

type UserSubscriptionFallback = UserSubscriptionBase & {
  type: 'fallback';
};

type UserSubscriptionFieldSales = UserSubscriptionBase & {
  type: 'field_sales';
  price: Price;
};

type UserSubscriptionIAP = UserSubscriptionBase & {
  type: 'iap';
  label?: string;
  uniqueSellingPoints: UniqueSellingPoint[];
};

type UserSubscriptionPartnerSales = UserSubscriptionBase & {
  type: 'partner_sales';
  price: Price;
};

type UserSubscriptionStaff = UserSubscriptionBase & {
  type: 'staff';
};

type UserSubscriptionTve = UserSubscriptionBase & {
  type: 'tve';
};

type ChildLock = {
  enabled: boolean;
};

export type User = {
  id: string;
  tvAccountId: string;
  name?: string;
  username?: string;
  email: string;
  childLock: ChildLock;
  subscriptions: UserSubscription[];
  profile?: Profile;
  showSelectionMenuItem: boolean;
};

const convertUniqueSellingPoint = ({
  sellingPoint,
  description,
}: SubscriptionProductUniqueSellingPoint): UniqueSellingPoint => ({
  sellingPoint,
  description: description ?? undefined,
});

const createUserSubscriptionStandard = ({
  id,
  name,
  price,
  label,
  uniqueSellingPoints,
  packageType,
  activationUrl,
}: SubscriptionProductStandard): UserSubscriptionStandard => ({
  type: 'standard',
  id,
  name,
  label: label?.text ?? undefined,
  price,
  uniqueSellingPoints: uniqueSellingPoints.map(convertUniqueSellingPoint),
  external: false,
  services: [],
  packageType: packageType ?? 'STANDARD',
  activationUrl: activationUrl ?? undefined,
});

const createUserSubscriptionFieldSales = ({
  id,
  name,
  price,
  packageType,
  activationUrl,
}: SubscriptionProductFieldSales): UserSubscriptionFieldSales => ({
  type: 'field_sales',
  id,
  name,
  price,
  external: false,
  services: [],
  packageType: packageType ?? 'STANDARD',
  activationUrl: activationUrl ?? undefined,
});

const createUserSubscriptionPartnerSales = ({
  id,
  name,
  price,
  packageType,
  activationUrl,
}: SubscriptionProductPartnerSales): UserSubscriptionPartnerSales => ({
  type: 'partner_sales',
  id,
  name,
  price,
  external: false,
  services: [],
  packageType: packageType ?? 'STANDARD',
  activationUrl: activationUrl ?? undefined,
});

const createUserSubscriptionIAP = ({
  id,
  name,
  label,
  uniqueSellingPoints,
  packageType,
  activationUrl,
}: SubscriptionProductIap): UserSubscriptionIAP => ({
  type: 'iap',
  id,
  name,
  label: label?.text ?? undefined,
  uniqueSellingPoints: uniqueSellingPoints.map(convertUniqueSellingPoint),
  external: false,
  services: [],
  packageType: packageType ?? 'STANDARD',
  activationUrl: activationUrl ?? undefined,
});

const createUserSubscriptionDualEntry = ({
  id,
  name,
  packageType,
  activationUrl,
}: SubscriptionProductFragment): UserSubscription => ({
  type: 'dual_entry',
  id,
  name,
  external: false,
  services: [],
  packageType: packageType ?? 'STANDARD',
  activationUrl: activationUrl ?? undefined,
});

const createUserSubscriptionFallback = ({
  id,
  name,
  packageType,
  activationUrl,
}: SubscriptionProductFragment): UserSubscription => ({
  type: 'fallback',
  id,
  name,
  external: false,
  services: [],
  packageType: packageType ?? 'STANDARD',
  activationUrl: activationUrl ?? undefined,
});

const createUserSubscriptionStaff = ({
  id,
  name,
  packageType,
  activationUrl,
}: SubscriptionProductFragment): UserSubscriptionStaff => ({
  type: 'staff',
  id,
  name,
  external: false,
  services: [],
  packageType: packageType ?? 'STANDARD',
  activationUrl: activationUrl ?? undefined,
});

const createUserSubscriptionTve = ({
  id,
  name,
  packageType,
  activationUrl,
}: SubscriptionProductFragment): UserSubscriptionTve => ({
  type: 'tve',
  id,
  name,
  external: false,
  services: [],
  packageType: packageType ?? 'STANDARD',
  activationUrl: activationUrl ?? undefined,
});

const createUserSubscriptionExternal = (
  type: UserSubscriptionType,
  { id, name, packageType, activationUrl }: SubscriptionProductFragment,
): UserSubscriptionExternal => ({
  type,
  id,
  name,
  external: true,
  services: [],
  packageType: packageType ?? 'STANDARD',
  activationUrl: activationUrl ?? undefined,
});

const createUserSubscription = (
  subscriptionProduct: SubscriptionProductFragment,
): UserSubscription => {
  const type = subscriptionProduct.__typename;
  switch (type) {
    case 'SubscriptionProductStandard':
      return createUserSubscriptionStandard(subscriptionProduct);
    case 'SubscriptionProductDualEntry':
      return createUserSubscriptionDualEntry(subscriptionProduct);
    case 'SubscriptionProductFallback':
      return createUserSubscriptionFallback(subscriptionProduct);
    case 'SubscriptionProductFieldSales':
      return createUserSubscriptionFieldSales(subscriptionProduct);
    case 'SubscriptionProductPartnerSales':
      return createUserSubscriptionPartnerSales(subscriptionProduct);
    case 'SubscriptionProductStaff':
      return createUserSubscriptionStaff(subscriptionProduct);
    case 'SubscriptionProductIAP':
      return createUserSubscriptionIAP(subscriptionProduct);
    case 'SubscriptionProductTVE':
      return createUserSubscriptionTve(subscriptionProduct);
    case 'SubscriptionProductExternalStandard':
      return createUserSubscriptionExternal('standard', subscriptionProduct);
    case 'SubscriptionProductExternalDualEntry':
      return createUserSubscriptionExternal('dual_entry', subscriptionProduct);
    case 'SubscriptionProductExternalFallback':
      return createUserSubscriptionExternal('fallback', subscriptionProduct);
    case 'SubscriptionProductExternalFieldSales':
      return createUserSubscriptionExternal('field_sales', subscriptionProduct);
    case 'SubscriptionProductExternalPartnerSales':
      return createUserSubscriptionExternal('partner_sales', subscriptionProduct);
    case 'SubscriptionProductExternalStaff':
      return createUserSubscriptionExternal('staff', subscriptionProduct);
    case 'SubscriptionProductExternalIAP':
      return createUserSubscriptionExternal('iap', subscriptionProduct);
    case 'SubscriptionProductExternalTVE':
      return createUserSubscriptionExternal('tve', subscriptionProduct);
  }
  throw new Error('Unrecognized subscription product type: ' + type);
};

export const getUser = async (): Promise<User> => {
  const account = Account.instance();
  const getUserPromise = commonGraph.getUser();
  const getAccountPromise = account.getAccountInformation();
  const getSubscriptionsPromise = cvsGateway.getSubscriptions({ cacheClass });
  const [userResponse, { id, tvAccountId, username, email }, { subscriptions: subscriptionTree }] =
    await Promise.all([getUserPromise, getAccountPromise, getSubscriptionsPromise]);

  if (!userResponse.data.user) {
    throw new UserNotFoundError();
  }

  const {
    name,
    childLock,
    currentProfile,
    subscriptions: userSubscriptions,
    showSelectionMenuItem,
  } = userResponse.data.user;

  const userSubscriptionIds = (userSubscriptions ?? []).map(({ subscription: { id } }) => id);

  const childSubscriptionIds = new Set<string>();
  for (const { id, subscriptionIds } of subscriptionTree) {
    if (userSubscriptionIds.includes(id)) {
      for (const childId of subscriptionIds) {
        childSubscriptionIds.add(childId);
      }
    }
  }

  const remainingUserSubscriptionIds = userSubscriptionIds.filter(
    (id) => !childSubscriptionIds.has(id),
  );

  const topLevelSubscriptions = (userSubscriptions ?? []).filter(({ subscription: { id } }) =>
    remainingUserSubscriptionIds.includes(id),
  );

  const subscriptions = topLevelSubscriptions.map(({ subscription }) => {
    const userSubscription = createUserSubscription(subscription);
    const subscriptionTreeItem = subscriptionTree.find(({ id }) => id === userSubscription.id);
    if (subscriptionTreeItem) {
      for (const serviceId of subscriptionTreeItem.subscriptionIds) {
        const serviceItem = subscriptionTree.find(({ id }) => id === serviceId);
        if (serviceItem) {
          const { id, name, iconUrl } = serviceItem;
          const serviceSubscription = userSubscriptions?.find(
            ({ subscription }) => subscription.id === id,
          );
          userSubscription.services.push({
            id,
            name,
            iconUrl,
            packageType: serviceSubscription?.subscription.packageType ?? 'STANDARD',
            activationUrl: serviceSubscription?.subscription.activationUrl ?? undefined,
          });
        }
      }
    }
    return userSubscription;
  });

  return {
    id,
    tvAccountId,
    username,
    name: name ?? undefined,
    childLock: {
      enabled: childLock.enabled,
    },
    subscriptions,
    showSelectionMenuItem,
    email,
    ...(currentProfile && {
      profile: {
        id: currentProfile.id,
        alias: currentProfile.alias,
        ageGroup: currentProfile.ageGroup,
        avatar: {
          url: currentProfile.avatar.head.sourceNonEncoded,
        },
      },
    }),
  };
};

type UpdateChildLockProps = {
  newPinCode?: string;
  enabled: boolean;
};

export const updateChildLock = async ({
  newPinCode,
  enabled,
}: UpdateChildLockProps): Promise<void> => {
  const response = await commonGraph.updateChildLock({ newPinCode, enabled });
  if (response.data?.updateChildLock.__typename !== 'UpdateChildLockResultOk') {
    throw new UserUpdateChildLockError();
  }
  clearCache();
};

export const isDualEntry = (user: User): boolean => {
  return !!user.subscriptions.find((subscription) => subscription.type === 'dual_entry');
};

type RentalPinCodeState = {
  set: boolean;
  active: boolean;
};

export const getRentalPinCodeState = async (): Promise<RentalPinCodeState> => {
  const engagementInfo = await engagementInformationGateway.getEngagementInfo({ cacheClass });
  return {
    set: engagementInfo.pinCodeSet,
    active: engagementInfo.pinActivated,
  };
};

export const validateRentalPinCode = async (pinCode: string): Promise<boolean> => {
  return await engagementGateway.validateRentalPinCode({ cacheClass, pinCode });
};

export const changeRentalPinCode = async (oldPinCode: string, newPinCode: string) => {
  await engagementGateway.changeRentalPinCode({ cacheClass, oldPinCode, newPinCode });
  clearCache();
};

export const createRentalPinCode = async (newPinCode: string) => {
  await engagementGateway.createRentalPinCode({
    newPinCode,
    cacheClass,
  });
  await setRentalPinCodeActive(newPinCode, true);
  clearCache();
};

export const setRentalPinCodeActive = async (pinCode: string, active: boolean) => {
  await engagementGateway.setRentalPinCodeActive({
    pinCode,
    active,
    cacheClass,
  });
  clearCache();
};

export const resetPinCode = async (newPinCode: string, token: string) => {
  await engagementGateway.resetPinCode({
    newPinCode,
    token,
    cacheClass,
  });
  clearCache();
};

export const forgotPinCode = async () => {
  await engagementGateway.forgotPinCode({
    pinIndex: 1,
    cacheClass,
  });
};
export const requestPinCode = async () => {
  await engagementGateway.forgotPinCode({
    pinIndex: 1,
    cacheClass,
  });
};

export const clearCache = () => {
  commonGraph.clearGetUserCache();
  CacheManager.instance().clearCacheClass(cacheClass);

  // Track the user after the cache has been cleared
  getUser().then((user) => trackSetUserProperties(user));
};

export const userChangeEvent = Account.statics.events.accountInformation;

type AccountInfo = {
  accountProvider?: {
    type?: string;
    url?: string;
  };
};
export const getAccountInfo = async (): Promise<AccountInfo> => {
  return engagementGateway.accountInfo(cacheClass);
};

type ConnectedStatusResponse = {
  error?: Error;
  connectedFederators: [] | Array<engagementGateway.Federator>;
};
export const getConnectedStatus = async (): Promise<ConnectedStatusResponse> => {
  return engagementGateway.connectedStatus(cacheClass);
};

type DoDeleteConnectionResponse = {
  error?: Error;
};
export const doDeleteConnection = async (
  federator: engagementGateway.Federator,
): Promise<DoDeleteConnectionResponse> => {
  return engagementGateway.deleteConnection({ federator, cacheClass });
};

export type AuhorizationPayload = {
  country: string;
  openIdToken: string;
  code: string;
  federatedBy: engagementGateway.Federator;
  deviceType: string;
  deviceId: string;
};

type FederatorAuthorizationResponse = {
  error?: Error;
  token?: string;
  refreshToken?: string;
  expiresIn?: number;
  scope?: string;
  idToken?: string;
  tokenType?: string;
  accountInfo?: AccountInfo;
  connectedStatus?: ConnectedStatusResponse;
  doDeleteConnection?: DoDeleteConnectionResponse;
};

export const federatorAuthorization = async (
  payload: AuhorizationPayload,
): Promise<FederatorAuthorizationResponse> => {
  return engagementGateway.federatorAuthorization({ payload, cacheClass });
};
