import styled from '@emotion/styled';
import { m } from 'framer-motion';

export const StyledSideMenuContainer = styled(m.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.designTokens.color.background.tertiary};
  width: 300px;
  height: 100%;
  overflow-y: scroll;
  user-select: none;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // no scrollbar
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Opera */
  }
`;

export const StyledSideMenuGeneralSection = styled.div`
  padding: 20px 0px;
`;
export const StyledSideMenuUserSection = styled.div`
  background-color: ${({ theme }) => theme.designTokens.color.background.surfaceDark};
  padding: 20px 0px;
`;
