export class PageNotFoundError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, PageNotFoundError.prototype);
    this.name = 'PageNotFoundError';
  }
}

export class UserNotFoundError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, UserNotFoundError.prototype);
    this.name = 'UserNotFound';
  }
}

export class AccountInfoNotFoundError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, UserNotFoundError.prototype);
    this.name = 'AccountInfoNotFound';
  }
}

export class ProductNotFoundError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, ProductNotFoundError.prototype);
    this.name = 'ProductNotFoundError';
  }
}

export class UserUpdateChildLockError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, UserUpdateChildLockError.prototype);
    this.name = 'UserUpdateChildLockError';
  }
}
