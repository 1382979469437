import { ErrorComponent, SectionSpinner } from 'components';
import { useTranslation } from 'react-i18next';
import {
  StyledSectionSubHeading,
  StyledSectionHeading,
  StyledSubSectionContainer,
} from '../navigation.styles';
import { StyledSectionSubHeadingDescription } from 'pages/Settings/components/TeliaSettings/TeliaSettings.styles';
import { useFetchFunction } from 'hooks/useFetchFunction';
import { getAccountInfo, getUser, isDualEntry } from 'services/userService';
import { useLogger } from 'hooks';
import { AccountInfoNotFoundError, UserNotFoundError } from 'errorTypes';
import {
  CustomerNumber,
  DualEntryInformation,
  Email,
  Name,
  Password,
  Username,
  FederatedLogin,
} from './components';
import { isCustomerNumberVisible, isNameVisible, isUsernameVisible } from './Account.utils';
import { getCountry } from 'utils';
import { TeliaSubscriptionNorway } from 'pages/Subscription/components/TeliaSubscription/TeliaSubscriptionNorway';
import { FeatureEnabled } from 'components/FeatureEnabled';

export const Account = () => {
  const { t } = useTranslation();
  const logger = useLogger('Account');
  const { data: user, loading, error } = useFetchFunction(getUser);
  const {
    data: customerAccount,
    loading: loadingAccount,
    error: errorAccount,
  } = useFetchFunction(getAccountInfo);

  if (error) {
    return <ErrorComponent error={error} />;
  } else if (errorAccount) {
    logger.error('Failed to load account info', errorAccount);
    throw errorAccount;
  }

  if (loading || loadingAccount) {
    return <SectionSpinner />;
  }

  if (!user) {
    logger.error('No user found');
    throw new UserNotFoundError();
  } else if (!customerAccount) {
    logger.error('No customer account info found');
    throw new AccountInfoNotFoundError();
  }

  const isNorway = getCountry() === 'NO';
  const isPartnerCustomer = customerAccount?.accountProvider?.type !== 'TELIA';
  const isNorwegianPartnerCustomer = isNorway && isPartnerCustomer;

  return (
    <>
      <StyledSectionHeading>{t('ACCOUNT_INFO_HEADING')}</StyledSectionHeading>
      {isDualEntry(user) ? (
        <DualEntryInformation user={user} />
      ) : (
        <>
          <StyledSectionSubHeading>{t('ACCOUNT_INFO_SECONDARY_HEADING')}</StyledSectionSubHeading>
          <StyledSubSectionContainer>
            {isUsernameVisible() && <Username user={user} />}
            {isNameVisible() && <Name user={user} />}
            {isCustomerNumberVisible() && <CustomerNumber user={user} />}
            <Email user={user} hideChangeButton={isNorwegianPartnerCustomer} />
            {!isNorwegianPartnerCustomer && <Password user={user} />}
            {isNorwegianPartnerCustomer && (
              <TeliaSubscriptionNorway isPartnerCustomer={isPartnerCustomer} />
            )}
          </StyledSubSectionContainer>

          <FeatureEnabled featureToggles={['Account.Federated.Login']}>
            <StyledSectionSubHeading>{t('ACCOUNT_INFO_THIRD_HEADING')}</StyledSectionSubHeading>
            <StyledSectionSubHeadingDescription>
              {t('ACCOUNT_FEDERATED_INFORMATION')}
            </StyledSectionSubHeadingDescription>
            <StyledSubSectionContainer>
              <FederatedLogin user={user} />
            </StyledSubSectionContainer>
          </FeatureEnabled>
        </>
      )}
    </>
  );
};
