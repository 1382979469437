import { MenuItem } from '../../hooks';
import { StyledMenuItemInfo, StyledSmartLink } from './MenuItemLink.styles';

type MenuItemLinkProps = {
  onClick?: () => void;
  menuItem: MenuItem;
};

export const MenuItemLink = ({ onClick, menuItem }: MenuItemLinkProps) => {
  return (
    <StyledSmartLink to={menuItem.url} onClick={() => menuItem.onClick?.() && onClick?.()}>
      {menuItem.label}
      {menuItem.info && <StyledMenuItemInfo>{menuItem.info}</StyledMenuItemInfo>}
    </StyledSmartLink>
  );
};
