import { gql } from '@apollo/client';

export const GET_USER_QUERY = gql`
  query getUser {
    user {
      name
      childLock {
        enabled
      }
      currentProfile {
        id
        alias
        ageGroup
        avatar {
          head {
            sourceNonEncoded
          }
        }
      }
      subscriptions {
        subscription {
          ...SubscriptionProduct
        }
      }
      showSelectionMenuItem
    }
  }

  fragment SubscriptionProduct on SubscriptionProduct {
    __typename
    id
    name
    activationUrl
    packageType
    ... on SubscriptionProductStandard {
      label {
        text
      }
      price {
        readable
      }
      uniqueSellingPoints {
        sellingPoint
        description
      }
    }
    ... on SubscriptionProductPartnerSales {
      price {
        readable
      }
    }
    ... on SubscriptionProductFieldSales {
      price {
        readable
      }
    }
    ... on SubscriptionProductIAP {
      label {
        text
      }
      iTunesConnectId
      uniqueSellingPoints {
        sellingPoint
        description
      }
    }
  }
`;
