import styled from '@emotion/styled';
import { m } from 'framer-motion';

export const StyledDropDownMenuContainer = styled(m.div)`
  flex-direction: column;
  padding: 0;
  background: ${({ theme }) => theme.designTokens.color.background.tertiary};
  position: absolute;
  top: 34px;
  z-index: 1;
  border-radius: 8px;
  filter: drop-shadow(0px 2px 40px rgba(0, 0, 0, 0.5));
  min-width: 308px;
  overflow: hidden;
  right: 0;

  & > .active > div {
    background: ${({ theme }) => theme.designTokens.color.button.ghostHover};
  }
`;
