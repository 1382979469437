import styled from '@emotion/styled';
import { SearchLink } from '..';

export const StyledNavigationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledUserAndSearchWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-right: 32px;
  }
`;

export const StyledSearchLink = styled(SearchLink)`
  margin-right: 12px;
`;
