import styled from '@emotion/styled';
import { textSecondary } from 'theme/theme.utils';

export const StyledSectionSubHeadingDescription = styled.p`
  ${({ theme }) => textSecondary(theme)}
  margin-top: 0px;
  margin-bottom: 8px;
`;

export const StyledPinCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
