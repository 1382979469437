import { css, Theme } from '@emotion/react';

type FontSpec = {
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: number;
  lineHeight?: string;
  textTransform?: string;
};

const font = ({ fontFamily, fontSize, fontWeight, lineHeight, textTransform }: FontSpec) =>
  css`
    ${fontFamily ? `font-family: ${fontFamily};` : ''}
    ${fontSize ? `font-size: ${fontSize};` : ''}
    ${fontWeight ? `font-weight: ${fontWeight};` : ''}
    ${lineHeight ? `line-height: ${lineHeight};` : ''}
    ${textTransform ? `text-transform: ${textTransform};` : ''}
  `;

export const contentLabel = (theme: Theme) => font(theme.designTokens.typography.contentLabel);
export const textPrimary = (theme: Theme) => font(theme.designTokens.typography.textPrimary);
export const textSecondary = (theme: Theme) => font(theme.designTokens.typography.textSecondary);
export const headingPrimary = (theme: Theme) => font(theme.designTokens.typography.headingPrimary);
export const headingSecondary = (theme: Theme) =>
  font(theme.designTokens.typography.headingSecondary);
export const headingTertiary = (theme: Theme) =>
  font(theme.designTokens.typography.headingTertiary);
export const subHeading = (theme: Theme) => font(theme.designTokens.typography.subHeading);
export const navigationText = (theme: Theme) => font(theme.designTokens.typography.navigationText);
export const textLink = (theme: Theme) => font(theme.designTokens.typography.textLink);
export const buttonText = (theme: Theme) => font(theme.designTokens.typography.buttonText);
export const contentDataSmall = (theme: Theme) =>
  font(theme.designTokens.typography.contentDataSmall);
export const textPrimaryEmphasis = (theme: Theme) =>
  font(theme.designTokens.typography.textPrimaryEmphasis);
