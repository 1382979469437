import styled from '@emotion/styled';
import { textSecondary } from 'theme/theme.utils';
import { SmartLink } from 'components';

export const StyledMenuItemLabel = styled.div`
  color: #fff;
  ${({ theme }) => textSecondary(theme)}
`;

export const StyledMenuItemInfo = styled.div`
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
  font-size: 13px;
`;

export const StyledMenuItem = styled.div`
  padding: 12px 24px 12px 40px;
  list-style-type: none;

  transition: background 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.designTokens.color.button.ghostHover};
  }
`;

export const StyledSmartLink = styled(SmartLink)`
  display: block;
`;
