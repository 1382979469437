import { Button, Icon } from '@telia-company/tv.oneapp-web-ui';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trackOverlay } from 'tracking';
import {
  StyledButtonStrip,
  StyledCloseButton,
  StyledContent,
  StyledHeading,
  StyledModal,
} from './ConfirmModal.styles';

export type OnResponse = (confirmed: boolean, setBusy: (busy: boolean) => void) => void;

type ConfirmModalProps = {
  isOpen: boolean;
  title: string;
  showTitle?: boolean;
  children?: ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;
  onResponse?: OnResponse;
  confirmDisabled?: boolean;
  showCancel?: boolean;
  showConfirm?: boolean;
  customButtons?: ReactNode;
};

export const ConfirmModal = ({
  isOpen,
  title,
  children,
  confirmLabel,
  cancelLabel,
  onResponse,
  confirmDisabled,
  customButtons,
  showTitle = true,
  showConfirm = true,
  showCancel = true,
}: ConfirmModalProps) => {
  const { t } = useTranslation();
  const closeIcon = Icon({ name: 'Close', width: '24px' });
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (isOpen) {
      trackOverlay({ overlayTitle: title });
    }
  }, [title, isOpen]);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (isOpen) {
        if (e.key === 'Escape') {
          onResponse?.(false, setBusy);
          e.preventDefault();
          e.stopPropagation();
        }
        if (e.key === 'Enter' && !busy && !confirmDisabled) {
          onResponse?.(true, setBusy);
          e.preventDefault();
          e.stopPropagation();
        }
      }
    };
    document.addEventListener('keydown', onKeyDown);

    return () => document.removeEventListener('keydown', onKeyDown);
  }, [busy, confirmDisabled, isOpen, onResponse]);

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={() => {
        onResponse?.(false, setBusy);
      }}
    >
      {showTitle && <StyledHeading>{title}</StyledHeading>}
      <StyledCloseButton onClick={() => onResponse?.(false, setBusy)}>
        {closeIcon}
      </StyledCloseButton>
      {children && (
        <StyledContent>{typeof children === 'string' ? <p>{children}</p> : children}</StyledContent>
      )}
      <StyledButtonStrip>
        {customButtons}
        {showCancel && (
          <Button variant="secondary" onClick={() => onResponse?.(false, setBusy)} disabled={busy}>
            {cancelLabel || t('COMMON_BUTTON_CANCEL')}
          </Button>
        )}
        {showConfirm && (
          <Button
            variant="primary"
            onClick={() => onResponse?.(true, setBusy)}
            isLoading={busy}
            disabled={busy || confirmDisabled}
          >
            {confirmLabel || t('COMMON_BUTTON_CONFIRM')}
          </Button>
        )}
      </StyledButtonStrip>
    </StyledModal>
  );
};
