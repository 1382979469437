import { ConfirmModal } from 'components/modals';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedItem, SelectableItem } from 'services/selectionMenuService';
import { StyledInfoContainer, StyledInfoLine } from './ActivateModal.styles';

export type ActivateModalHandle = {
  open: (item: SelectableItem | FixedItem) => void;
};

export const ActivateModal = forwardRef<ActivateModalHandle>((_props, ref) => {
  const { t } = useTranslation();
  const [item, setItem] = useState<SelectableItem | FixedItem>();

  useImperativeHandle(ref, () => ({
    open: (openItem) => {
      setItem(openItem);
    },
  }));

  if (!item) {
    return null;
  }

  const lines = t('SELECTIONMENU_ACTIVATE_MODAL_INFO', { name: item.name }).split('\n\n');
  console.log(lines);

  return (
    <ConfirmModal
      isOpen={true}
      title={t('SELECTIONMENU_ACTIVATE_MODAL_HEADING', { name: item.name })}
      onResponse={() => {
        if (item.externalRegistration) {
          window.location.href = item.externalRegistration?.url;
        }
        setItem(undefined);
      }}
      showCancel={false}
      confirmLabel={t('SELECTIONMENU_ITEM_MODAL_BUTTON_ACTIVATE')}
    >
      <StyledInfoContainer>
        {lines.map((line, i) => (
          <StyledInfoLine key={i}>{line}</StyledInfoLine>
        ))}
      </StyledInfoContainer>
    </ConfirmModal>
  );
});
