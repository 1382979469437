import { MenuItem } from '../../hooks';
import {
  StyledMenuItem,
  StyledMenuItemInfo,
  StyledMenuItemLabel,
  StyledSmartLink,
} from './DropDownMenuItem.styles';

type DropDownMenuItemProps = {
  menuItem: MenuItem;
};
export const DropDownMenuItem = ({ menuItem }: DropDownMenuItemProps) => {
  const { url, label, info, onClick } = menuItem;

  return (
    <StyledSmartLink to={url} onClick={() => onClick?.()}>
      <StyledMenuItem>
        <StyledMenuItemLabel>{label}</StyledMenuItemLabel>
        {info && <StyledMenuItemInfo>{info}</StyledMenuItemInfo>}
      </StyledMenuItem>
    </StyledSmartLink>
  );
};
