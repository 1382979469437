import styled from '@emotion/styled';

export const StyledHamburgerButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  display: flex;
  > svg {
    width: 32px;
    height: 32px;
  }
`;
