import { useTranslation } from 'react-i18next';
import { SelectableItem } from 'services/selectionMenuService';
import {
  StyledButton,
  StyledCard,
  StyledCardImage,
  StyledCardInfo,
  StyledTagContainer,
} from './Card.styles';
import { getImageProxyLogoUrl } from './getLogoUrl.utils';
import { useContext, useRef } from 'react';
import { SelectionMenuContext } from '../../SelectionMenuContext';
import { AddedTag } from './AddedTag';
import { MissingPointsModal, MissingPointsModalHandle } from '../ItemModal/MissingPointsModal';
import { ActivateTag } from './ActivateTag';
import { ActivateModal, ActivateModalHandle } from '../ItemModal/ActivateModal';
import { shouldActivateItem } from './shouldActivateItem.utils';

type SelectableCardProps = {
  item: SelectableItem;
};
export const SelectableCard = ({ item }: SelectableCardProps) => {
  const { t } = useTranslation();
  const {
    selectedPoints,
    totalPoints,
    oldSelectedItems,
    activatableTvPackageIds,
    onSelectableItemChange,
    openItemModal,
  } = useContext(SelectionMenuContext);
  const missingPointsModalRef = useRef<MissingPointsModalHandle>(null);
  const activateModalRef = useRef<ActivateModalHandle>(null);

  const { id, logoUrl, isSelected, name, points } = item;
  const imageProxyUrl = getImageProxyLogoUrl(logoUrl);
  const missingPoints = Math.max(0, (points ?? 0) + selectedPoints - totalPoints);

  const isActivatable =
    isSelected &&
    shouldActivateItem(item, activatableTvPackageIds) &&
    !!oldSelectedItems.find((i) => i.id === id);

  const onButtonClicked = () => {
    if (isActivatable) {
      activateModalRef.current?.open(item);
      return;
    }

    if (!item.isSelected && missingPoints) {
      missingPointsModalRef.current?.open(item);
      return;
    }

    item.isSelected = !item.isSelected;
    onSelectableItemChange(item);
  };

  const buttonLabel = isActivatable
    ? t('SELECTIONMENU_ITEM_ACTIVATE')
    : isSelected
    ? t('SELECTIONMENU_SCORETABLE_BUTTON_REMOVE')
    : missingPoints > 0
    ? t('SELECTIONMENU_ITEM_MISSING_POINTS', { points: missingPoints })
    : t('SELECTIONMENU_SCORETABLE_BUTTON_ADD');

  return (
    <>
      <StyledCard
        onClick={() => openItemModal(item)}
        active={isSelected}
        activatable={isActivatable}
      >
        <StyledTagContainer>
          {isActivatable && <ActivateTag />}
          {isSelected && <AddedTag />}
        </StyledTagContainer>
        <StyledCardImage src={imageProxyUrl} alt={name} />
        <StyledCardInfo>{t('SELECTIONMENU_ITEM_POINTS', { points })}</StyledCardInfo>
        <StyledButton
          variant={isActivatable ? 'primary' : 'secondary'}
          onClick={(e) => {
            e.stopPropagation();
            onButtonClicked();
          }}
        >
          {buttonLabel}
        </StyledButton>
      </StyledCard>
      <MissingPointsModal ref={missingPointsModalRef} />
      <ActivateModal ref={activateModalRef} />
    </>
  );
};
