import {
  StyledNavigationWrapper,
  StyledSearchLink,
  StyledUserAndSearchWrapper,
} from './HeaderViewLarge.styles';
import { MenuItem } from '../../hooks';
import { UserDropDown } from './UserDropDown';
import { HeaderContainer } from '../HeaderContainer';
import { MenuItemLink } from './MenuItemLink';
import { User } from 'services/userService';
import { LogoHorizontal } from 'components';

type HeaderViewLargeProps = {
  user?: User;
  menuItems: MenuItem[];
  userMenuItems?: MenuItem[];
};

export const HeaderViewLarge = ({ user, menuItems, userMenuItems }: HeaderViewLargeProps) => {
  return (
    <HeaderContainer>
      <StyledNavigationWrapper>
        <LogoHorizontal />
        {menuItems.map((menuItem, index) => (
          <MenuItemLink key={index} menuItem={menuItem} />
        ))}
      </StyledNavigationWrapper>
      <StyledUserAndSearchWrapper>
        {user && userMenuItems && <UserDropDown user={user} menuItems={userMenuItems} />}
        <StyledSearchLink />
      </StyledUserAndSearchWrapper>
    </HeaderContainer>
  );
};
