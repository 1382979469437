import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'services/userService';
import { oneAppUrl } from 'utils';
import { MenuItem } from './useGetMenuItems';
import { TFunction } from 'react-i18next';
import { isFeatureEnabled } from 'featureToggles';
import { shouldUseEnvironmentsProviderWithCookies } from 'providers/utils';
import { logoutWithEnvironmentSelectorEnabled } from '../components/utils/logoutWithEnvironmentSelectorEnabled';
import { logout } from '../components/utils/logout';

type UseGetUserMenuItemsProps = {
  user?: User;
};

type UserMenuProps = {
  translationFn: TFunction<'translation', undefined>;
  user: User;
};

const USER_MENU_ITEMS = ({ translationFn, user }: UserMenuProps): MenuItem[] => [
  ...(user.showSelectionMenuItem
    ? [
        {
          id: 'selection-menu',
          label: translationFn('HEADER_USERMENU_SELECTION_MENU'),
          url: '/subscription/selection-menu',
        },
      ]
    : []),
  {
    id: 'my-account',
    label: translationFn('HEADER_USERMENU_MY_ACCOUNT'),
    url: '/account',
  },
  {
    id: 'edit-profiles',
    label: translationFn('HEADER_USERMENU_EDIT_PROFILES_TELIA'),
    url: oneAppUrl('/profiles/edit'),
  },
  {
    id: 'continue-watching',
    label: translationFn('HEADER_USERMENU_CONTINUE_WATCHING'),
    url: oneAppUrl('/continue-watching'),
  },
  {
    id: 'my-list',
    label: translationFn('HEADER_USERMENU_MY_LIST'),
    url: oneAppUrl('/my-list'),
  },
  ...(isFeatureEnabled(['Help.ShowFeedback'])
    ? [
        {
          id: 'feedback',
          label: translationFn('HEADER_USERMENU_FEEDBACK'),
          url: oneAppUrl('/feedback'),
        },
      ]
    : []),

  {
    id: 'logout',
    label: translationFn('HEADER_USERMENU_LOGOUT'),
    url: '/', // not used, since we handle the logout with onClick
    info: user.username,
    onClick: () => {
      if (shouldUseEnvironmentsProviderWithCookies()) {
        logoutWithEnvironmentSelectorEnabled();
      } else {
        logout();
      }
    },
  },
];

export const useGetUserMenuItems = ({ user }: UseGetUserMenuItemsProps) => {
  const { t } = useTranslation();
  const [userMenuItems, setUserMenuItems] = useState<MenuItem[] | undefined>();

  useEffect(() => {
    if (user) {
      const menuProps: UserMenuProps = { translationFn: t, user };
      setUserMenuItems(USER_MENU_ITEMS(menuProps));
    } else {
      setUserMenuItems(undefined);
    }
  }, [user, t]);

  return { userMenuItems };
};
