import styled from '@emotion/styled';
import { LogoHorizontal } from 'components/LogoHorizontal';
import { HeaderContainer } from '../HeaderContainer';

export const StyledHeaderContainer = styled(HeaderContainer)`
  position: relative;
  height: 56px;
  z-index: 3;
  padding: 0 16px;
  > div:first-of-type {
    height: 56px;
    padding: 0 16px;
  }
`;

export const StyledLogo = styled(LogoHorizontal)`
  z-index: -1;
  inset: 0;
  position: absolute;
  top: 0;
  justify-content: center;
`;
