import { useTranslation } from 'react-i18next';
import { getImageProxyLogoUrl } from './getLogoUrl.utils';
import { FixedItem } from 'services/selectionMenuService';
import {
  StyledButton,
  StyledCard,
  StyledCardImage,
  StyledCardInfo,
  StyledTagContainer,
} from './Card.styles';
import { useContext, useRef } from 'react';
import { SelectionMenuContext } from '../../SelectionMenuContext';
import { ActivateTag } from './ActivateTag';
import { ActivateModal, ActivateModalHandle } from '../ItemModal/ActivateModal';
import { shouldActivateItem } from './shouldActivateItem.utils';

type FixedCardProps = {
  item: FixedItem;
};

export const FixedCard = ({ item }: FixedCardProps) => {
  const { t } = useTranslation();
  const { openItemModal, activatableTvPackageIds } = useContext(SelectionMenuContext);
  const imageProxyUrl = getImageProxyLogoUrl(item.logoUrl);
  const activateModalRef = useRef<ActivateModalHandle>(null);

  const isActivatable = shouldActivateItem(item, activatableTvPackageIds);

  return (
    <>
      <StyledCard active onClick={() => openItemModal(item)} activatable={isActivatable}>
        <StyledTagContainer>{isActivatable && <ActivateTag />}</StyledTagContainer>

        <StyledCardImage src={imageProxyUrl} alt={item.name} />
        <StyledCardInfo>{t('SELECTIONMENU_ITEM_INCLUDED')}</StyledCardInfo>
        {isActivatable && (
          <StyledButton
            variant="primary"
            onClick={(e) => {
              e.stopPropagation();
              activateModalRef.current?.open(item);
            }}
          >
            {t('SELECTIONMENU_ITEM_ACTIVATE')}
          </StyledButton>
        )}
      </StyledCard>
      <ActivateModal ref={activateModalRef} />
    </>
  );
};
