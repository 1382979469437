import { AgeGroup, User, UserSubscriptionType } from 'services/userService';

type SetUserPropertiesEvent = {
  event: 'set_user_properties';
  app_name: 'c more web' | 'telia play web';
  client: 'account-web';
  user_id: string;
  user_market: string;
  user_subscriptions: string[];
  user_subscription_types: UserSubscriptionType[];
  user_profile_id: string;
  user_profile_type: string;
  page_name: string;
};

type PageEvent = {
  event: 'page';
  page_name: string;
};

type OverlayEvent = {
  event: 'overlay';
  overlay_title: string;
  page_name: string;
};

type PanelImpression = {
  event: 'panel_impression';
  panel_id: string;
  panel_row_index: number;
  page_name: string;
};

type PanelAssetImpression = {
  event: 'asset_impression';
  content_media_id: string;
  panel_id: string;
  panel_row_index: number;
  page_name: string;
};

type PanelAssetClick = {
  event: 'asset_click';
  content_media_id: string;
  panel_id: string;
  panel_row_index: number;
  page_name: string;
};

type CancelSubscription = {
  event: 'cancel_subscription';
  cancellation_package_id: string;
  page_name: string;
};

type CancellationAnswers = {
  event: 'cancellation_answers';
  [key: string]: string;
  page_name: string;
  cancellation_package_id: string;
};

type ResumeSubscription = {
  event: 'resume_subscription';
  page_name: string;
};

type LogoutAllDevices = {
  event: 'logout_all_devices';
  page_name: string;
};

type LogoutOtherDevice = {
  event: 'logout_other_device';
  device_id: string;
  page_name: string;
};
export type OfferImpression = {
  event: 'offer_impression';
  offer_package_id: string;
  offer_binding_time: '12m' | null;
  page_name: string;
};

type OfferClick = {
  event: 'offer_click';
  offer_package_id: string;
  offer_binding_time: '12m' | null;
  page_name: string;
};

export type UpsellSelectContent = {
  event: 'upsell_select_content';
  upsell_content_type: string;
  page_name: string;
};

type UpsellNoPackageClick = {
  event: 'upsell_no_package_click';
  upsell_button_text: string;
  page_name: string;
};

export type UpsellSelectSubscription = {
  event: 'upsell_select_subscription';
  upsell_subscription_type: string;
  page_name: string;
};

type Redirect = {
  event: 'redirect';
  target_page_name: string;
  page_name: string;
};

type ConfirmChangeToPackage = {
  event: 'confirm_change_to_package';
  change_to_package_id: string;
  page_name: string;
};

type ConfirmCancellationOffer = {
  event: 'confirm_cancellation_offer';
  change_to_package_id: string;
  page_name: string;
};

export type GTMEvent =
  | SetUserPropertiesEvent
  | PageEvent
  | OverlayEvent
  | PanelImpression
  | PanelAssetImpression
  | PanelAssetClick
  | CancelSubscription
  | CancellationAnswers
  | ResumeSubscription
  | LogoutAllDevices
  | LogoutOtherDevice
  | OfferImpression
  | OfferClick
  | UpsellSelectContent
  | UpsellNoPackageClick
  | UpsellSelectSubscription
  | Redirect
  | ConfirmChangeToPackage
  | ConfirmCancellationOffer;

declare global {
  interface Window {
    dataLayer: [GTMEvent];
  }
}

export const trackEvent = <T extends GTMEvent>(eventProps: OmitEach<T, 'page_name'>) =>
  window.dataLayer.push({
    page_name: window.location.pathname,
    ...eventProps,
  } as T);

export const getUserProfileType = (profile: NonNullable<User['profile']>) => {
  switch (profile.ageGroup) {
    case AgeGroup.Child0_6:
      return 'KIDS_0-6';
    case AgeGroup.Child:
      return 'KIDS_7-12';
    case AgeGroup.Adult:
      return 'ADULT';
  }
};

export type OmitEach<Type, Key extends keyof Type> = {
  [Property in keyof Type as Exclude<Property, Key>]: Type[Property];
};
