import { SectionSpinner } from 'components';
import { AccountInfoNotFoundError, UserNotFoundError } from 'errorTypes';
import { useLogger } from 'hooks';
import { useFetchFunction } from 'hooks/useFetchFunction';
import { StyledSectionSubHeading, StyledSubSectionContainer } from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { getUser, getAccountInfo } from 'services/userService';
import { TeliaNoSubscription, TeliaSubscription, TeliaSelectionMenu } from '..';
import { PurchaseHistory } from '../PurchaseHistory';
import { TeliaPayment } from '../TeliaPayment/TeliaPayment';
import { FeatureEnabled } from 'components/FeatureEnabled';
import { getCountry } from 'utils';
import { TeliaSubscriptionNorway } from '../TeliaSubscription/TeliaSubscriptionNorway';
import { getMediaPrepaids } from 'services/mediaPrepaidService';
import { MediaPrepaids } from './components';

export const TeliaSubscriptions = () => {
  const logger = useLogger('TeliaSubscriptions');
  const { data: user, loading: loadingUser, error: errorUser } = useFetchFunction(getUser);
  const {
    data: customerAccount,
    loading: loadingAccount,
    error: errorAccount,
  } = useFetchFunction(getAccountInfo);

  const {
    data: mediaPrepaids,
    loading: loadingMediaPrepaids,
    error: errorMediaPrepaids,
  } = useFetchFunction(getMediaPrepaids);

  const { t } = useTranslation();

  if (loadingUser || loadingAccount || loadingMediaPrepaids) {
    return <SectionSpinner />;
  }

  if (errorUser) {
    logger.error('Failed to load user', errorUser);
    throw errorUser;
  } else if (errorAccount) {
    logger.error('Failed to load account info', errorAccount);
    throw errorAccount;
  } else if (errorMediaPrepaids) {
    logger.error('Failed to load media prepaids', errorMediaPrepaids);
    // Never mind if the media prepaids failed - it's not critical
  }

  if (!user) {
    logger.error('No user found');
    throw new UserNotFoundError();
  } else if (!customerAccount) {
    logger.error('No customer account info found');
    throw new AccountInfoNotFoundError();
  }

  const isNorway = getCountry() === 'NO';
  const isPartnerCustomer = customerAccount?.accountProvider?.type !== 'TELIA';

  return (
    <>
      {!!mediaPrepaids?.length && <MediaPrepaids mediaPrepaids={mediaPrepaids} />}

      <StyledSectionSubHeading>{t('SUBSCRIPTION_SECONDARY_HEADING')}</StyledSectionSubHeading>
      {/* No subscription */}
      {user.subscriptions.length === 0 && <TeliaNoSubscription />}
      {user.subscriptions.length > 0 && (
        <StyledSubSectionContainer>
          {isNorway ? (
            <TeliaSubscriptionNorway
              manageSubscriptionLink={customerAccount?.accountProvider?.url}
              isPartnerCustomer={isPartnerCustomer}
            />
          ) : (
            user.subscriptions.map((sub) => (
              <TeliaSubscription userSubscription={sub} key={sub.id} />
            ))
          )}

          {/* The selection menu subsection should be shown if it's feature toggled AND user has a package */}
          {!(isNorway && isPartnerCustomer) && (
            <FeatureEnabled featureToggles={['Subscription.SelectionMenu']}>
              <TeliaSelectionMenu />
            </FeatureEnabled>
          )}
          {!(isNorway && isPartnerCustomer) && <TeliaPayment />}
        </StyledSubSectionContainer>
      )}
      <PurchaseHistory />
    </>
  );
};
