import styled from '@emotion/styled';
import { navigationText } from 'theme/theme.utils';

export const StyledAvatar = styled.img`
  height: 37px;
`;

export const StyledAvatarContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  ${({ theme }) => navigationText(theme)}
`;
