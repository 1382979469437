import styled from '@emotion/styled';

type StyledChevronContainerProps = {
  open: boolean;
  size: 'small' | 'normal';
};

export const StyledChevronContainer = styled.div(
  ({ open, size = 'normal' }: StyledChevronContainerProps) => `
  display: flex;
  align-items: center;
  width: ${size === 'normal' ? '24' : '16'}px;
  height: ${size === 'normal' ? '24' : '16'}px;
  transition: transform 0.3s ease;
  transform: ${open ? 'rotate(-180deg)' : 'rotate(0deg)'};

  & > svg {
    width: 100%;
  }
`,
);
