import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { headingTertiary, textSecondary } from 'theme/theme.utils';
import { SmartLink } from 'components';

export const StyledSmartLink = styled(SmartLink)`
  ${({ theme }) => css`
    ${headingTertiary(theme)}
    display: block;
    padding: 16px 40px;

    &.active {
      color: ${theme.designTokens.color.text.secondary};
    }
  `}
`;

export const StyledMenuItemInfo = styled.div`
  ${({ theme }) => css`
    ${textSecondary(theme)}
    color: ${theme.designTokens.color.text.secondary}
  `}
`;
